.projectview-container {
	//refers to projectview component container
	width: 100%;
	position: relative;
	height: 100%;
	.wrapper {
		width: fit-content;
		max-width: 100%;
		height: 100%;
		display: flex;
		margin: auto;
		justify-content: center;
		position: relative;
		.monitor {
			flex: 1;
			box-sizing: border-box;
			width: fit-content;
			max-width: 900px;
			height: 100%;
			position: relative;
			.macdesktop {
				display: block;
				width: auto;
				height: 100%;
			}
			.screen {
				z-index: 999;
				box-sizing: border-box;
				position: absolute;
				width: 100%;
				height: 100%;
				// padding: 3.5%;
				.loadingSpinner {
					margin-top: 20%;
				}
				.loadingScreen {
					position: absolute;
					width: 100%;
					h1 {
						color: black;
						text-align: center;
						font-size: 5em;
						margin-top: 5%;
					}
					@media screen and (max-width: 700px) {
						h1 {
							font-size: 1.5em;
						}
					}
				}
				iframe {
					display: block;
					overflow: hidden;
					width: 200%;
					height: 152%;
					transform-origin: top left;
					transform: scaleX(0.5) scaleY(0.5);
				}
				@media screen and (max-width: 800px) {
					iframe {
						width: 400%;
						height: 304%;
						transform-origin: top left;
						transform: scaleX(0.25) scaleY(0.25);
					}
				}
			}
		}
		$mouseWidth: 100px;
		.siteLinks {
			width: $mouseWidth;
			min-width: 40px;
			position: relative;
			a {
				display: block;
				position: absolute;
				bottom: 0;
				right: 0;
				img {
					width: 100%;
					max-width: $mouseWidth;
					bottom: 0;
					opacity: 0.7;
					&:hover {
						opacity: 1;
						transform: scale(1.1);
					}
				}
			}
		}
		@media screen and (max-width: 600px) {
			.siteLinks {
				width: 40px;
			}
		}
	}
}
