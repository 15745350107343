//Universal Styles

$smBreakPoint: 576px;
$mdBreakPoint: 768px;
$lgBreakPoint: 992px;
$xlBreakPoint: 1200px;
$xxlBreakPoint: 1400px;

body {
	background-color: black;
	overflow: hidden;
}

.page {
	position: relative;
	overflow: auto;
	overflow-x: hidden;
	top: 72px;
	height: calc(100vh - 72px);
}
@media screen and (min-width: $lgBreakPoint) {
	.page {
		top: 84px;
		height: calc(100vh - 84px);
	}
}

//Navbar Component Styles
#navigation {
	padding-top: 1rem;
	padding-bottom: 1rem;
	font-size: 1.5rem;
	.nav-link {
		text-decoration: none;
	}
	.dropdown-menu {
		background-color: black;
	}
	.dropdown-item:hover {
		background-color: black !important;
	}
	@media screen and (max-width: 767px) {
		.nav-item {
			text-align: center;
		}
	}
}

//Footer Styles
#footer {
	width: 100vw;
	height: 100px;
	background: black;
	color: white;
	font-size: large;
	position: absolute;
	bottom: 0px;
}

//Home Page Styles
.home-container {
	.imageContainer {
		position: relative;
		overflow: hidden;
		img {
			width: 100%;
		}
		.fade {
			//Change opacity of this one image
			position: absolute;
			top: 0;
			left: 0;
			animation-name: cloud-fade;
			animation-duration: 15s;
			animation-iteration-count: infinite;
		}
		.fog {
			position: absolute;
			animation-name: cloud-fade, fog-movement;
			animation-duration: 20s;
			animation-iteration-count: infinite;
			transform: scale(2);
		}
	}

	.fixed-image {
		width: 100%;
		position: fixed;
		z-index: -1;
	}
	.image-wrapper {
		position: relative;
		display: none;
	}

	.quote-box {
		position: absolute;
		bottom: 4rem;
		right: 2rem;
		width: 500px;
		font-size: 2rem;
		opacity: 0.2;
	}
	//Fog and Code Animations
	@keyframes cloud-fade {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 0.9;
		}
		100% {
			opacity: 0;
		}
	}
	@keyframes grow {
		from {
			width: 0px;
		}
		to {
			width: 100%;
		}
	}
	@keyframes fog-movement {
		0% {
			left: -25%;
			top: 0%;
		}
		50% {
			left: 50%;
			top: -50%;
		}
		100% {
			left: 0%;
			top: 0%;
		}
	}

	.statement-block {
		position: relative;
		height: 200px;
		background: black;
		color: white;
		font-size: 5rem;
		display: flex;
		align-items: center;
	}

	.camino-at-anchor {
		width: 100%;
	}

	.johnson-cay-panorama {
		width: 100%;
	}
	@media screen and (max-width: $smBreakPoint) {
		.imageContainer {
			height: 100vh;
			width: fit-content;
			img {
				object-fit: cover;
				height: 100%;
				width: auto;
			}
		}
		.fixed-image {
			width: fit-content;
			height: 100%;
			position: fixed;
			bottom: unset;
			top: 72px;
			z-index: -1;
		}
		.statement-block {
			height: 100px;
			font-size: 2rem;
			padding-left: 1rem;
		}
		.panAnimation5 {
			height: 100vh;
			animation: panAnimation 5s linear infinite; /* Adjust the duration as needed */
		}
		.panAnimation7 {
			height: 100vh;
			animation: panAnimationReverse 7s linear infinite; /* Adjust the duration as needed */
		}
		.panAnimation10 {
			height: 100vh;
			animation: panAnimation 10s linear infinite; /* Adjust the duration as needed */
		}
		@keyframes panAnimation {
			0% {
				transform: translateX(0);
			}
			50% {
				transform: translateX(-50%);
			}
			100% {
				transform: translateX(0);
			}
		}
		@keyframes panAnimationReverse {
			0% {
				transform: translateX(-50%);
			}
			50% {
				transform: translateX(0%);
			}
			100% {
				transform: translateX(-50%);
			}
		}
	}
}

.letter.boom {
	position: fixed;
	color: white;
	z-index: 100;
}

#building-block {
	.letter,
	.tools {
		visibility: hidden;
	}
}

/* Skills Page */
#skills-container {
	overflow: hidden;
	min-height: 100%;
	#skills-card {
		font-size: larger;
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 1rem;
		// z-index: 100;
		.card-body {
			display: flex;
			align-items: baseline;
		}
		.avatar {
			max-width: 400px;
			width: 25%;
			float: left;
			padding-right: 2rem;
			img {
				width: 100%;
			}
		}
	}

	#captured-skills {
		font-size: larger;
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 2rem;
		margin-bottom: 2rem;
		z-index: 80;
		.captured-skill {
			display: flex;
			align-items: center;
			img {
				width: 5rem;
			}
			.name {
				flex-grow: 1;
				font-size: 2rem;
				padding-left: 2rem;
			}
			.captured-skill-description {
			}
		}
	}
	.skill {
		width: 200px;
		height: 200px;
		max-width: 20%;
		position: absolute;
		z-index: 90;
		img {
			width: 100%;
		}
		.burst {
			visibility: hidden;
		}
	}
	.skill:hover {
		transform: scale(2);
		z-index: 101;
		.burst {
			visibility: visible;
			font-size: 10em;
			position: absolute;
			left: 0;
			top: 0;
			transform: translate(10%, 5%);
			color: orange;
		}
	}
	.skill.touchmove {
		transform: scale(2);
		z-index: 101;
		.burst {
			visibility: visible;
			font-size: 5em;
			position: absolute;
			left: 0;
			top: 0;
			transform: translate(10%, 5%);
			color: orange;
		}
	}
	#crosshairs {
		visibility: hidden;
		width: 200px;
		height: 200px;
		position: absolute;
		pointer-events: none;
		z-index: 110;
		top: 0;
		left: 0;
	}
	#score-board {
		position: fixed;
		top: calc(72px + 1rem);
		right: 2rem;
		z-index: 150;
		font-size: larger;
	}
	.blink {
		box-shadow: 0px 0px 20px #d6e715;
	}
	#joke-box {
		position: absolute;
		right: 0px;
		max-width: 90%;
		img {
			width: 100%;
		}
	}

	@media screen and (max-width: $lgBreakPoint) {
		#skills-card {
			margin-top: 10rem;
			.avatar {
				width: 50%;
				padding: 0;
				padding-right: 1rem;
			}
		}
		#score-board {
			left: 50%;
			right: unset;
			transform: translateX(-50%);
			text-wrap: nowrap;
		}
		.skill:hover {
			.burst {
				font-size: 4rem;
			}
		}
	}
}

.shadow-card {
	box-shadow: 0px 0px 10px #ccc;
	border-radius: 0 !important;
}

#projects-container {
	h1 {
		color: black;
	}

	ul {
		list-style: none;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
	}
	li {
		width: 100%;
		height: 500px;
		margin-bottom: 2em;
		position: relative;
	}
	@media screen and (max-width: 800px) {
		li {
			height: 300px;
		}
	}
	@media screen and (max-width: 600px) {
		li {
			height: 180px;
		}
	}
}

/* Contact */
.contact-container {
	min-height: 100%;
	.card {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 500px;
		max-width: 90%;
	}
}
.background-lettering {
	font-family: cursive;
	font-size: 4rem;
	position: fixed;
	left: 0;
	top: 80px;
	transform: rotate(-45deg);
	z-index: -1;
	white-space: pre-line;
}
.background-lettering2 {
	font-family: cursive;
	font-size: 4rem;
	position: fixed;
	right: 0;
	bottom: 80px;
	transform: rotate(-225deg);
	z-index: -1;
	white-space: pre-line;
}

/* Galaga */
#galaga-container {
	position: relative;
	padding: 0;
	#banner {
		height: 100px;
	}
	height: 100%;
	.card {
		background-color: green;
		.card-body {
			background-color: rgba(254, 156, 0, 0.8);
			#screen-wrapper {
				position: relative;
				height: 100%;
				#video-screen {
					border: 4px solid red;
					position: relative;
					background-color: black;
					width: 90%;
					height: 100%;
					margin-left: auto;
					margin-right: auto;
					overflow: hidden;
					#ship {
						width: 50px;
					}
					#ship.invincible {
						animation-name: blink;
						animation-duration: 0.3s;
						animation-iteration-count: infinite;
					}
					@keyframes blink {
						0% {
							opacity: 0;
						}
						100% {
							opacity: 1;
						}
					}

					.bullet {
						width: 20px;
					}

					.enemy {
						width: 30px;
						z-index: 1;
						img {
							width: 100%;
						}
					}
					#gameover {
						font-family: Orbitron;
						font-size: 10rem;
						color: white;
						text-shadow: 5px 2px 5px red;
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
						text-wrap: nowrap;
					}
					#reset-button {
						position: absolute;
						left: 50%;
						bottom: 100px;
						transform: translateX(-50%);
						font-size: xx-large;
					}
				}
			}
		}
	}
	#score-board {
		color: white;
		font-size: 1.2rem;
		#score-board-display {
			border: 4px solid gold;
			background-color: black;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			min-width: 100px;
			height: fit-content;
			text-align: end;
			@font-face {
				font-family: 'Orbitron';
				src: local('Orbitron'),
					url(./fonts/Orbitron/Orbitron-VariableFont_wght.ttf)
						format('truetype');
				/* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
			}
			font-family: 'Orbitron';
			font-size: 2rem;
			color: gold;
		}
	}
	#extra-lives {
		position: absolute;
		right: 10px;
		bottom: 10px;
		display: flex;
		flex-direction: column;
		img {
			width: 50px;
		}
	}
}
@media screen and (max-width: $smBreakPoint) {
	#galaga-container {
		padding: 0;
		#extra-lives {
			position: absolute;
			right: 5px;
			bottom: 3px;
			display: flex;
			flex-direction: column;
			img {
				width: 25px;
			}
		}
	}
}

#about-container {
	[class^='col-'] {
		margin-top: 2rem;
	}
	.card {
		height: 100%;
		.card-body {
			display: flex;
			flex-direction: column;
		}
	}
}
